import i18n from '@/components/i18n'

export const productModelTypeOptions = [
  { name: i18n.t('qtjx'), productType: '', networkType: '' },
  { name: i18n.t('zidingyi'), productType: '', networkType: '' },
  // ---- //
  { name: "JC500", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC500D", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC450", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC450 PRO", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC400", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC400P", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC400D", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC120", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC200", productType: i18n.t('shijueanfang'), networkType: "3G" },
  { name: "JC100", productType: i18n.t('shijueanfang'), networkType: "3G" },
  { name: "JH018", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JH016", productType: i18n.t('shijueanfang'), networkType: "3G" },
  { name: "JH09", productType: i18n.t('shijueanfang'), networkType: "3G" },
  { name: "JH012", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "BW600", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "T28", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "B9", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "B8H", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC181", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC261", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "JC261P", productType: i18n.t('shijueanfang'), networkType: "4G" },
  { name: "PL200", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  // ---- //
  { name: "LL702", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  { name: "LL701", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  { name: "LL02", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  { name: "LL01", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  { name: "LL303", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  { name: "LL302", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  { name: "LL301", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  { name: "BL11", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  { name: "CT10", productType: i18n.t('wuxianchanpin'), networkType: "3G" },
  { name: "AT6", productType: i18n.t('wuxianchanpin'), networkType: "3G" },
  { name: "AT5", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "AT4", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "AT3", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "AT2", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "AT1", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "LG05", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "LG02", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "LG01", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "Q2", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "Qbit", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "BL10", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "LG300", productType: i18n.t('wuxianchanpin'), networkType: "4G" },
  { name: "Qbit-M", productType: i18n.t('wuxianchanpin'), networkType: "2G" },

  { name: "BL300", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "LL306", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "GT710", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "S341", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "PG201", productType: i18n.t('wuxianchanpin'), networkType: "2G" },
  { name: "VL502", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "VL501", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "VL111", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "VL103", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "VL02", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "VL03", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "VL04", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "VL802", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "VL01", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "EV41S", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "GV40", productType: i18n.t('youxianchanpin'), networkType: "4G" },
  { name: "GV20", productType: i18n.t('youxianchanpin'), networkType: "3G" },
  { name: "GT06E", productType: i18n.t('youxianchanpin'), networkType: "3G" },
  { name: "VW01", productType: i18n.t('youxianchanpin'), networkType: "3G" },
  { name: "VG04", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "VG03", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "HVT001", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "VG02U", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "VG01U", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "VG502", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "OB22", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "X3", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "Wetrack2", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "Wetrack Lite/GV25", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "JV200", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "ET25", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "GT800", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "TR02", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "TR06", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "GT02", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "GT06N", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "GT06F", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "EG02", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "GT08", productType: i18n.t('youxianchanpin'), networkType: "2G" },
  { name: "EV26", productType: i18n.t('youxianchanpin'), networkType: "2G" },

  // ---- //
];

export const areaoptions = [
  {
    value: 0,
    label: i18n.t('dianshang'),
  },
  {
    value: 1,
    label: i18n.t('dongnanya'),
  },
  {
    value:2,
    label: i18n.t('guojika'),
  },
  {
    value: 3,
    label: i18n.t('guopjiduoge'),
  },
  {
    value:4,
    label: i18n.t('guoneika'),
  },

  {
    value: 5,
    label: i18n.t('guoneiwuxian'),
  },
  {
    value: 6,
    label: i18n.t('guoneiyouxina'),
  },
  {
    value: 7,
    label: i18n.t('beimei'),
  },
  {
    value: 8,
    label: i18n.t('lamei'),
  },
  {
    value: 9,
    label: i18n.t('nanya'),
  },
  {
    value: 10,
    label: i18n.t('ouzhou'),
  },
  {
    value: 11,
    label: i18n.t('zhongfei'),
  },
  {
    value: 12,
    label: i18n.t('dianmo'),
  },
  {
    value: 13,
    label: i18n.t('wuliuzichan'),
  },
  
];

export const problemtypeaoptions = [
  {
    value: 0,
    label: i18n.t('ptwt'),
  },
  {
    value:1,
    label: i18n.t('ruanjianwenti'),
  },
  {
    value: 2,
    label: i18n.t('yingjianwenti'),
  },
  {
    value: 3,
    label: i18n.t('lailiaowenti'),
  },
  {
    value: 4,
    label: i18n.t('zubaowenti'),
  },

  {
    value: 5,
    label: i18n.t('qitawetni'),
  },
 
];

export const prodectoptions = [
  {
    value: 0,
    label: i18n.t('shijueanfang'),
  },
  {
    value: 1,
    label: i18n.t('wuxianchanpin'),
  },
  {
    value: 2,
    label: i18n.t('youxianchanpin'),
  },
  {
    value: 3,
    label: i18n.t('xuehengka'),
  },
  {
    value:4,
    label: i18n.t('pingtai'),
  },
  {
    value: 5,
    label: "APP",
  },
];

export const netoptions = [
  {
    value: "2G",
    label: "2G",
  },
  {
    value: "3G",
    label: "3G",
  },
  {
    value: "4G",
    label: "4G",
  },
];

export const pingtaioptions = [
  {
    label: i18n.t('tqzx'),
    value: 0,
  },
  {
    label: i18n.t('tqsx'),
    value: 1,
  },

  { 
    label: i18n.t('fkpt'),
    value: 2,
  },
  {
    label: i18n.t('simkpt'),
    value: 3,
  },
  {
    label: i18n.t('hdd'),
    value: 4,
  },

  {
    label: i18n.t('aczx'),
    value: 5,
  },

  {
    label: "Tracksolid",
    value: 6,
  },

  {
    label: "TracksolidPro",
    value: 7,
  },

  {
    label: "Carmatrix",
    value: 8,
  },
  {
    label: i18n.t('fgdzpt'),
    value: 9,
  },
  {
    label: "jimi iot cam ",
    value: 10,
  },
  {
    label: i18n.t('qita'),
    value: 11,
  },
  {
    label: "LNCOON IOT",
    value: 12,
  },
  {
    label: "LNCOON",
    value: 12,
  },
];
export const complaintoptions= [
  {
    value: 0,
    label: i18n.t("fou"),
  },
  {
    value: 1,
    label: i18n.t("shi"),
  },
]

export const difoptions = [
  {
    value: 0,
    label: i18n.t("disanfang"),
  },
  {
    value: 1,
    label: i18n.t("huizhouruanjian"),
  },
  {
    value: 2,
    label: i18n.t("huizhouyingjian"),
  },
  {
    value: 3,
    label: i18n.t("jishuzhichi"),
  },
  {
    value: 4,
    label: i18n.t("kehu"),
  },
  {
    value: 5,
    label: i18n.t("pingtaizhongxin"),
  },
  {
    value: 6,
    label: i18n.t("shenzhenruanjian"),
  },
  {
    value: 7,
    label: i18n.t("shenzhenyingjian"),
  },
  {
    value: 8,
    label: i18n.t("wu"),
  },
];

export const attributeoptions = [
  {
    value: 0,
    label: i18n.t("xuqiu"),
  },
  {
    value: 1,
    label: i18n.t("yanfa"),
  },
]


export const solvedFlagoptions = [
  {
    value: 0,
    label: "未解决",
  },
  {
    value: 1,
    label: "已解决",
  },
]
